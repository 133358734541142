@use '../../variables';

.add-new-coworkers-wrap {
  display: flex;

  .coworkers-list {
    margin-left: 100px;

    .coworkers-list-item {
      margin-top: 30px;
      display: flex;
      align-items: center;

      .item-info {
        display: flex;

        .statusLabel {
          font-size: 13px;
          color: variables.$G3;
          margin-left: 20px;
        }
      }
      .controls-icons {
        color: variables.$G2;
        cursor: pointer;
        margin-left: 20px;
  
        &:hover {
          color: variables.$activeIconColor;
        }
      }
    }
  }
}

@media only screen and (max-width: variables.$phone) {
  .add-new-coworkers-wrap {
    flex-direction: column;

    .coworkers-list {
      margin-left: 0;
      margin-top: 20px;

      .coworkers-list-item {
        margin-top: 10px;

        .item-info {
          flex-direction: column;

          .statusLabel {
            margin-left: 0;
          }
        }

        .controls-icons {
          margin-left: 16px;
          height: 3em;
          width: auto;
        }
      }
    }
  }
}