@use '../variables';

.calendar-wrap {
  position: relative;

  .disable-calendar-wrap {
    position: absolute;
    top: 0;
    z-index: 12;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    > div {
      position: absolute;
      background-color: #fff;
      opacity: 0.8;
      width: 100%;
      height: 100%;
    }
    p {
      z-index: 2;
      padding: 10px;
      background-color: #fff;
      border-radius: 20px;
    }
  }
  .warningMessage {
    color: variables.$errorText;
  }

  // rewrite original styles
  
  .rdrCalendarWrapper {
    margin-top: 50px;
    font-size: 16px;

    .rdrMonthAndYearPickers select {
      display: block;
    }
    
    .rdrDays {
      button:focus {
        background-color: unset;
      }
    }
  }

  @media only screen and (max-width: variables.$phone) {
    .rdrCalendarWrapper {
      margin: 0 auto;
      display: block;
      font-size: 12px;

      .rdrDateDisplayWrapper {
        background: none;

        .rdrDateInput {
          border: 1px solid variables.$G1;
          input {
            margin: 0;
            font-size: 14px;
          }
        }
      }
      .rdrMonthsVertical {
        .rdrMonth {
          width: 100%;
        }
      }
    }
  }
}