@use 'variables';

.login-bg {
  background-color: variables.$color-five;

  .login-wrap {
    background-image: url("../static/images/landing-bg.jpg");
    background-size: 100% auto;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
  
    .login-lang-select {
      margin-top: 6%;
  
      button {
        background-color: unset;
        box-shadow: unset;
        padding: 15px 60px 50px 60px;
        border-radius: 50px;
        border-width: 3px;
  
        &:hover {
          box-shadow:inset 0px 0px 0px 3px #fff;
        }
      }
    }
  
    .login-title {
      width: 100%;
      margin-top: 10%;
      text-align: center;
      padding: 0 20px 20px 20px;
      background: rgba(70, 128, 144,0.6);
      backdrop-filter: blur(10px);
      h1 {
        font-size: 2.6rem;
      }
    }
  
    .login-button {
      margin-top: 8%;
      background-color: variables.$color-four;
      box-shadow: unset;
      padding: 15px 100px 50px 100px;
      border-radius: 50px;
      border: 3px solid #fff;
  
      &:hover {
        background-color: variables.$color-four;
        filter: brightness(1.2);
      }
    }
  }
}

@media only screen and (max-width: variables.$phone) {
  .login-bg {
    .login-wrap {
      background-size: cover;

      .login-lang-select {
        margin-top: 30%;
    
        button {
          padding: 0px 20px 0px 20px;
          color: variables.$color-four;
          font-weight: bold;
        }
      }

      .login-title {
        h1 {
          font-size: 1.6rem;
        }
      }
    }
  }
}