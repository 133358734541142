@use '../variables';

.filters-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  
  .submit-button {
    margin-left: 20px;
  }
}

.validation-message {
  width: fit-content;
  margin: 0 auto;
  color: variables.$errorText;
};

.card-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  min-height: 400px;

  .card .card-title {
    font-size: 18px;
  }
  .card-component {
    margin: 5px;
  }
  .nothing-found {
    margin: 40px auto;
  }
}

@media only screen and (max-width: variables.$phone) {
  .filters-wrap {
    padding: 0 20px;
    display: block;

    .submit-button {
      display: block;
      width: 300px;
      margin: 0 auto;
    }
  }

  .card-wrap {
    padding: 0 20px;
    .card-component {
      min-width: 90%;
      margin: 0;
      margin-bottom: 10px;
    }
  }
}