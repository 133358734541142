@use 'variables';

.terms-and-conditions-wrap {
  padding-top: 50px;

  .tabs .tab {
    a, a.active {
      color: #000;
      font-size: 20px;
    }
  }

  .accept-form {
    position: fixed;
    padding: 30px 0;
    z-index: 10;
    width: 100%;
    margin: 0 auto;
    border-bottom: 2px solid variables.$G3;
    background-color: #fff;
    text-align: center;

    .accept-button {
      margin-left: 40px;
    }
  }

  .tabs {
    margin-top: 100px;;
  }

  .tab-content {
    padding: 0 60px;
  }
}
