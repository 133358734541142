@use '../variables';

.remove-account-wrap {
  margin-top: 40px;

  h5 {
    margin-bottom: 20px;
  }

  .remove-account-warning {
    color: variables.$waring-text
  }

  .remove-account-btn {
    margin-left: 40px;
  }
}

@media only screen and (max-width: variables.$phone) {
  .remove-account-wrap {
    margin-top: 0;
    display: flex;
    flex-direction: column;

    .remove-account-btn {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}
