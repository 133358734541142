@use 'variables';

.key-features {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  padding: 40px 60px;

  width: 100%;
  background: rgba(114, 144, 70, 0.6);
  backdrop-filter: blur(10px);

  .key-features-list {
    padding-right: 40px;

    li {
      display: flex;
      align-items: baseline;      

      strong {
        font-size: 20px;
        width: 50%;
        max-width: 260px;
      }

      p {
        padding-left: 20px;
      }
    }
  }

  .preview-img {
    width: 100%;
    height: fit-content;
    max-width: 740px;
  }
}

@media only screen and (max-width: variables.$smallScreen) {
  .key-features {
    flex-direction: column;

    .key-features-list {
      padding-right: 0;
  
      li {
        strong {
          font-size: 18px;
        }
      }
    }
  }
}

@media only screen and (max-width: variables.$phone) {
  .key-features {
    .key-features-list {
      li {
        display: block;
      }
    }
  }
}