@use '../variables';

.card-component-wrap {
  max-width: 400px;
  min-width: 350px;

  .edit-mode-buttons-wrap {
    margin: 0 0 20px 0;
    display: flex;
    justify-content: space-evenly
  }

  .card-header {
    margin-bottom: 20px;
    .card-name {
      font-size: 24px;
    }
  }

  .select {
    margin-left: 5px;
    margin-top: 40px;

    label: {
      left: 4px;
    }

    input {
      color: variables.$cartTextColor;
    }
  }

  .card-action {
    .disabled {
      background-color: variables.$cartDisabledButton !important;;
    }
  }
}

@media only screen and (max-width: variables.$phone) {
  .card-component-wrap {
    max-width: 100%;
    width: 100%;

    td {
      padding: 8px 5px;
    }
  }
}
