@use '../variables';

.order-summary-wrap {
  // max-width: 500px;
  display: flex;
  border-top: 2px solid variables.$color-three;
  padding-top: 30px;

  .cost-details {
    min-width: 260px;

    .status {
      margin-top: 40px;
    }
    .allNightsCost {
      color: variables.$labelColor;
      font-size: 1rem;
    }
    .totalCost {
      margin-top: 40px;

      input {
        border-bottom: 1px solid variables.$G2;
      }
    }
    .markedInput {
      outline: 2px solid variables.$waring;
      outline-offset: 20px;
    }
    .finalCostWarning {
      color: variables.$waring-text;
      font-size: 12px;
    }
  }

  .description {
    margin-left: 40px;
    padding-left: 20px;
    border-left: 1px solid variables.$G3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Number of lines to show */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  input {
    font-size: unset!important; // materialize - fix side border in inputs
  }
}

@media only screen and (max-width: variables.$phone) {
  .order-summary-wrap {
    flex-direction: column;

    .description {
      margin-left: 0;
      padding-left: 0;
      border-left: none;
    }
  }
}

