@keyframes growAndShrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.all-properties {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;

  .add-new-property-wrap {
    display: flex;
    flex-direction: column;

    .nothing-found {
      margin: 10px auto;
      animation: growAndShrink 1s ease-out 1s forwards;
    }
  }
}
