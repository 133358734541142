@use '../variables';

.property-item-wrap {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 300px;
  height: 147px;
  min-width: 100px;
  margin: 10px 10px 0 0; 
  @include variables.cart-shadow;

  .property-item-content {
    display: flex;

    .property-item-content-title {
      padding-right: 20px;
      color: variables.$G3;
    }
    .property-item-content-value {
      font-weight: 600;

      .property-item-description {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }
  }

  .property-item-controls {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: fit-content;

    .controls-icons {
      color: variables.$G2;
      cursor: pointer;

      &:hover {
        color: variables.$activeIconColor;
      }
    }
  }
}