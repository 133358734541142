@use '../variables';

.order-details-wrap {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 500px;
  width: 300px;

  .switch {
    margin-left: 12px; 
  }
  .switchLabel {
    color: variables.$G2;
  }

  input {
    font-size: unset!important; // this line fix side border in materialize inputs
  }

  .order-details-comment {
    padding-left: 10px;
  }
}



@media only screen and (max-width: variables.$smallScreen) {
  .order-details-wrap {
    margin-top: 20px;
    flex-direction: row;
    justify-content: space-around;
    max-width: 100%;
    width: 100%;
  }
}
