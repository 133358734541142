@use '../variables';

// .timeline-item-wrap {
//   border-radius: 50px!important;
// }

.timeline-noGroupExists-wrap {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  font-size: 30px;

  .menu-button {
    margin-left: 20px;
    path {
      stroke: variables.$color-four;
    }
  }
}

@media only screen and (max-width: variables.$phone) {
  .timeline-noGroupExists-wrap {
    width: 90%;
    font-size: 20px;
  }
}

.timeline-wrap {
  padding-top: 80px;

  .groupDisabled {
    background-color: variables.$G0; // If Group is Disabled in the settings
  }

  .vis-timeline.vis-bottom.vis-ltr {
    .vis-panel.vis-background {

    }
    .vis-panel.vis-background.vis-vertical {
      .vis-axis {
        .vis-group {
        }
      }
      .vis-time-axis.vis-background {
      }
      .vis-current-time {
      }
    }
    .vis-panel.vis-background.vis-horizontal {
    }
    .vis-panel.vis-center {
      .vis-content {
        .vis-itemset {
          .vis-background {
            .vis-group { // x
              .vis-item.vis-range {
                // border-radius: 50px!important;
                // border: 1px solid #0f0!important;
                // border-radius: 50px!important;
              }
              .vis-item.vis-background.itemClassName {
                // border: 1px solid #0f0!important;
                // border-radius: 50px!important;
                // height: 40px!important;
                .vis-item-overflow {
                  
                  .vis-item-content {
                    
                  }
                }
              }
            }
          }
          .vis-foreground {
            .vis-group {
              .vis-item.vis-range { // TimeLine item
                border-radius: 50px!important;
                min-width: 34px; // Width for single day item
                // border: 1px solid #0f0!important;
                .vis-delete {
                  display: none;
                }
                .vis-item-overflow {
                  .vis-item-content {
                    .timeline-item-wrap {

                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .vis-panel.vis-bottom {
      display: none; // hide bottom labels (months, days)
    }
  }
}
