@use '../variables';

.cart-content-edit {
  .datepicker {
    color: variables.$cartTextColor;
  }
}
.cart-row {
  display: flex;
  align-items: center;
  height: 60px;
  p {
    min-width: 140px; 
    color: variables.$cartTextColor;
  }
  .input-field input, .input-field textarea {
    color: variables.$cartTextColor;
  }
  .switch {
    label {
      color: variables.$cartTextColor;
    }
  }
}