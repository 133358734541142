@use '../variables';

.property-item-edit {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 300px;
  min-width: 100px;
  margin: 10px 10px 0 0; 
  @include variables.cart-shadow;

  .property-item-edit-form {
    width: 100%;

    .property-item-edit-buttons-wrap {
      margin-top: 40px;
      display: flex;
      justify-content: space-around;

      .cance-button {
        @include variables.no-bg-button;
      }
    }
  }
}