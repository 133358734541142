@use 'variables';

.footer-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  width: 100%;
  height: 100px;
  background-color: variables.$color-four;
  border-top: 4px solid #fff;
}
