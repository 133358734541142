@use '../variables';

.date-picker-wrap {
  display: flex;
  margin-right: 20px;

  & > div:nth-child(2) {
    margin-left: 20px;
  }

  .datepicker-day-button {
    color: #000;
  }

  .is-disabled .datepicker-day-button{
    color: variables.$G2;
  }

  .is-today .datepicker-day-button{
    color: unset;
  }

  .is-selected .datepicker-day-button{
    color: #fff;
  }
}

@media only screen and (max-width: variables.$phone) {
  .date-picker-wrap {
    margin-right: 0;
  }
}