@use '../variables';

.settings-add-new-item-wrap {
  margin-top: 40px;

  .add-new-item-form {
    display: flex;
    align-items: baseline;
    > * {
      margin-right: 20px;
      min-width: 100px;
    }
  }
  .input-field input {
    color: #000;
  }

  @media only screen and (max-width: variables.$tablet) {
    .add-new-item-form  {
      flex-direction: column;
      align-items: stretch;
    }
  }
}
