@use '../variables';

.timelineFiltersWrap {
  display: flex;
  align-items: center;
  height: 40px;
  margin: -20px 80px 20px 80px;

  .select {
    width: 100px;
    margin-right: 20px;
  }
  .monthsButtonsWrap {
    display: flex;
    align-items: center;

    .monthButton {
      margin-right: 2px;
      font-size: 10px;
      padding: 5px 10px;
      line-height: normal;
      height: 25px;
    }
  }
  .mobileMonthsSelectWrap {
    display: none;
  }
  .monthButtonSelected {
    border-bottom: 2px solid #fff;
  }
  .todayButton {
    margin-left: 20px;
    font-size: 10px;
    padding: 5px 10px;
    line-height: normal;
    height: 25px;
  }
}

@media only screen and (max-width: variables.$phone) {
  .timelineFiltersWrap {
    margin: -20px 0px 20px 20px;

    .select {
      width: 80px;
    }
    .monthsButtonsWrap {
      display: none;
    }
    .mobileMonthsSelectWrap {
      display: block;
    }
  }
}
