$color-one: #26a69a;
$color-two: #000000;
$color-three: #67afc6;
$color-four: #468090;
$color-three-light: #c6e2eb;
$color-five: #878333;

$G0: #f3f3f3;
$G1: #dddddd;
$G2: #9e9e9e; //#a7a7a7
$G3: #737373;
$activeIconColor: #000;

$cartTextColor: #fff;
$cartDisabledButton: #697f8a;
$errorText: #a82300;
$waring: #ffaa03;
$waring-text: #b77900;

$labelColor: $G2;

$max-width: 1200px;

$phone: 936px - 1;
$tablet: 1024px - 1;
$smallScreen: 1300px;

@mixin cart-shadow {
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.10);
  -moz-box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.10);
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.10);
}

@mixin no-bg-button {
  background: none;
  box-shadow: none;
  &:hover {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),
    0 3px 1px -2px rgba(0,0,0,0.12),
    0 1px 5px 0 rgba(0,0,0,0.2);
  }
}

@mixin text-shadow {
  text-shadow: 1px 1px 1px #2f2f2f;
}
