@use '../variables';

.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;

  .modal-main {
    position:fixed;
    overflow: auto;
    padding: 30px;
    background: white;
    color: #000;
    min-width: 600px;
    width: max-content;
    max-width: 90%;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  
    .button-wrap {
      margin-top: 20px;
    }
    .modal-close-btn {
      position: absolute;
      top: 4px;
      right: 4px;
      font-size: 20px;
      cursor: pointer;
      width: 30px;
      height: 30px;
    }
  }
  .warning {
    height: auto;
  }
  .common {
    height: inherit; // Full height modal. Fix issue when modal is higher than view port
  }

  @media only screen and (max-width: variables.$phone) {
    .modal-main {
      min-width: initial;

      .button-wrap {
        button {
          font-size: 12px;
        }
      }
    }
  }
}

.show-modal {
  display: block;
}

.hide-modal {
  display: none;
}