@use 'variables';
@use './common/loader';
@use './common/alert';
@use './header';
@use './search/search';
@use './search/card-order-component';
@use './common/datepicker';
@use './common/modalComponent';
@use './search/cart-content-edit';
@use './side-panel';
@use './settings/add-new-items';
@use './settings/property-item';
@use './settings/all-properties';
@use './settings/property-item-edit';
@use './timeline/order-view';
@use './timeline/property-list';
@use './timeline/order-details';
@use './footer';
@use './termsAndConditions';
@use './settings/change-language';
@use './login';
@use './common/date-range-picker';
@use './settings/user-settings-wrap';
@use './settings/set-feeding';
@use './timeline/timeline';
@use './timeline/timelineFilters';
@use './settings/coworkers/add-coworkers';
@use './settings/coworkers/coworker-request-management';
@use './timeline/order-summary';
@use './settings/removeAccount';
@use './key-features';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto!important;
}

.page-container {
  padding: 20px 80px 60px 80px;
  overflow: hidden;
}

@media only screen and (max-width: variables.$smallScreen) {
  .page-container {
    padding: 20px 20px 60px 20px;
  }
}

@media only screen and (max-width: variables.$phone) {
  .page-container {
    padding: 20px 10px 60px 10px;

    h3 {
      text-align: center;
    }
  }
}
