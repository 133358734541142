.alert {
  position: relative;
  max-width: 500px;
  height: auto;
  margin: 0 auto 20px auto;
  padding: 20px;
  background-color: #fff;
  -webkit-box-shadow: 0px 5px 15px 4px rgba(0,0,0,0.09);
  -moz-box-shadow: 0px 5px 15px 4px rgba(0,0,0,0.09);
  box-shadow: 0px 5px 15px 4px rgba(0,0,0,0.09);

  .close-icon {
    position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer;  
  }
}

.alert-error {
  border: 1px solid #f00;
}
