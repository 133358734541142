@use '../variables';

.order-view-wrap {
  margin-bottom: 60px;

  .flex-container {
    display: flex;
    width: 100%;
  
    .item-first {
      flex-grow: 2;
      max-width: 500px;
    }
    .item-second {
      // flex-grow: 1;
      padding-left: 40px;
    }
    .item-third {
      flex-grow: 2;
      padding-left: 60px;
    }
  }

  .buttons-wrap {
    display: flex;
    align-items: center;

    .submit-button {
      display: block;
      margin: 0 auto;
      width: 300px;
    }
    .remove-icons {
      color: variables.$color-one;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: variables.$smallScreen) {
    .flex-container {
      flex-wrap: wrap; 

      .item-first {
        width: 48%; 
      }
      .item-second {
        width: 48%; 
      }
      .item-third {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: variables.$phone) {
    .flex-container {
      justify-content: center;

      .item-first {
        width: 100%; 
        max-width: none;
      }
      .item-second {
        width: 100%; 
        padding-left: 0;
        border-top: 4px solid variables.$color-three-light;
      }
      .item-third {
        margin-top: 20px;
        width: 100%;
        padding-left: 0;
        border-top: 4px solid variables.$color-three-light;
      }
    }
  }
}
