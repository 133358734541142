@use 'variables';

.side-nav-wrap {
  overflow-x: hidden;
  right: 0;
  z-index: 30;
  width: 100%;
  height: 100%;

  .open-nav-button {
    position: fixed;
    z-index: 99;
    right: 0;
    margin: 4px 20px 0 0;
    font-size: 3rem;
    cursor: pointer;
    
    path {
      stroke: #fff;
    }
  }
  .side-nav-content {
    padding: 40px 0;

    .sidenav-close {
      position: fixed;
      right: 79%;
      top: 6px;
      cursor: pointer;  
      background-color: #fff;
      border-radius: 50%;
    }

    .sidenav-row-even {
      padding: 20px 40px;
      background-color: variables.$G0;
    }

    .sidenav-row-odd {
      padding: 20px 40px;
    }
  }
  .hidden {
    display: none;
  }

  @media only screen and (max-width: variables.$phone) {
    .side-nav-content {
      padding: 0 0 40px 0;

      .sidenav-row-even {
        padding: 20px 10px 40px 20px;
      }
  
      .sidenav-row-odd {
        padding: 20px 10px 40px 20px;
      }
    }
  }
}
