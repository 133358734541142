@use '../variables';

.property-list-wrap {
  max-height: 400px;
  margin-top: 50px;
  padding-left: 10px;
  padding-bottom: 10px;
  overflow: auto;
  user-select: none;

  .disabled-message {
    position: absolute;
    right: 6px;
    top: -11px;
    font-size: 10px;
    color: variables.$waring-text;
  }

  .property-item {
    position: relative;
    min-width: 100px;
    max-width: 500px;
    margin: 10px 10px 0 0; 
    @include variables.cart-shadow;

    .selectable-area {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .selected {
      border: 2px solid variables.$color-three;
      border-radius: 4px;
    }

    .content {
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 10px;
      border: 1px solid variables.$G1;
      border-radius: 4px;


  
      .title {
        width: 150px;
        padding-right: 20px;
        color: variables.$G3;

        p {
          margin: 10px 0;
        }
      }
      .value {
        font-weight: 600;
        width: 100px;

        p {
          margin: 10px 0;
        }
      }
    }
  }

  .disabledGroup {
    pointer-events: none;
    background-color: variables.$G0;
    > div {
      opacity: 40%;
    }
  }

  .nothing-found {
    margin: 40px auto;
  }
}

@media only screen and (max-width: variables.$phone) {
  .property-list-wrap {
    width: 100%;
    padding-left: 0;

    .disabled-message {
      position: absolute;
      left: 10px;
      top: -12px;
    }

    .property-item {
      max-width: 100%;
      margin: 10px 0 0 0; 

      .content {
        padding: 10px;
        .title p {
          margin: 0;
        }
        .value p {
          margin: 0;
        }
      }
    }
  }
}
