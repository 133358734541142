@use 'variables';

.header-container {
  // overflow: hidden;

  .header-wrap {
    position: fixed;
    z-index: 20;
    background-color: variables.$color-three;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row-reverse;
    padding-top: 8px;
    padding-right: 40px;
    
    .right-layout {
      display: flex;
      align-items: center;
      padding-left: 5px;
  
      .nickname {
        margin-top: 10px;
        color: #fff;
        @include variables.text-shadow;
      }
  
      .logout-button {
        margin: -5px 60px 0 40px;
        color: #fff;
        @include variables.text-shadow;
      }
    }
  }
}

.header-error-alert {
  z-index: 22;
  top: 80px;
  padding-bottom: 20px;
}